import React from 'react';
import CountdownTimer1 from './CountdownTimer1';

import './AppTimer.css';

export default function App(timeleft) {
  const THREE_DAYS_IN_MS = timeleft.first * 1000;
  //const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
    <div>
      
      <CountdownTimer1 targetDate={dateTimeAfterThreeDays} />
    </div>
  );
}
