import Web3 from 'web3';
import React from 'react';
import { forwardRef, useRef } from "react";

//import MultiPool1 from './MultiPoolV6';
import SinglePool1 from './SinglePoolV6';



import AbiMyFactory from "./contracts/MyFactoryV6.json";

import { useState, useEffect } from "react";
import './SinglePool1.css';


function PoolsV6() {



const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const contractAddressPool = urlParams.get("pool");
const descpool = urlParams.get("desc");
const thelang = urlParams.get("lang");


const contractAddressFactoryPolygonDemo = "0xb48871cAEba7EB72740c84D3B5e44d42B2EEc65f";
const contractAddressFactoryPolygonMain = "0xd7aB3Bb9a44b5e33af9197112e997B2fb6A8AEdf";
//const contractAddressFactoryBscDemo = "0xEE4b7c306D189554fcA0413880DA3B2634806Cb9";


const web3polygondemo = new Web3('https://rpc.ankr.com/polygon_mumbai');
//const web3polygondemo = new Web3('https://rpc-mumbai.maticvigil.com');
const web3polygonmain = new Web3('https://polygon-rpc.com/');
//const web3bscdemo = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');



const contractfactorypolygondemo = new web3polygondemo.eth.Contract(AbiMyFactory, contractAddressFactoryPolygonDemo);
const contractfactorypolygonmain = new web3polygonmain.eth.Contract(AbiMyFactory, contractAddressFactoryPolygonMain);
//const contractfactorybscdemo = new web3bscdemo.eth.Contract(AbiMyFactory, contractAddressFactoryBscDemo);  



///////////////////////////////////////////


const [blockchain, setblockchain] = useState(0);
const [web4, setweb4] = useState("");
const [namechain, setnamechain] = useState("");
const [theurl, settheurl] = useState("");
const [poolselected, setpoolselected] = useState(3);

    useEffect(() => {

      
      //async function fetchdata() {

    
    HandlePoolType();    

      
      //}
    
    
     

     //fetchdata();

      
      
     
     
  }, []);


////////////////////////////////////////////////////////////////////////////////////

const HandlePoolType = async () => {

var poolfounded = 0;

var ispoolpolygondemo = [];
ispoolpolygondemo = await contractfactorypolygondemo.methods.mapPools(contractAddressPool).call();


  if (Web3.utils.isAddress(ispoolpolygondemo[0])) {


  //if (ispoolpolygondemo[4] == 1 || ispoolpolygondemo[4] == 2) {
  
    setweb4(new Web3("https://rpc.ankr.com/polygon_mumbai"));
    setblockchain(80001); 
    setnamechain("Mumbai"); 
    settheurl("mumbai.polygonscan.com");
    setpoolselected(1);

    poolfounded = 1;

  }  



if (poolfounded == 0) {

var ispoolpolygonmain = [];
ispoolpolygonmain = await contractfactorypolygonmain.methods.mapPools(contractAddressPool).call();


if (Web3.utils.isAddress(ispoolpolygonmain[0])) {

//  if (ispoolpolygonmain[4] == 1 || ispoolpolygonmain[4] == 2) {
  
    setweb4(new Web3("https://polygon-rpc.com/"));
    setblockchain(137); 
    setnamechain("Polygon"); 
    settheurl("polygonscan.com");
    setpoolselected(1);

    poolfounded = 1;

  }  
}





}



return (




    
<div className="SinglePool1">    

<div class="container-sm bg-transparent pt-2 text-center" >






{poolselected == 1 &&
<SinglePool1 fourth={web4} first={contractAddressPool} second={blockchain} third={namechain} url={theurl} thedesc={descpool} thelang={thelang} />
}








</div>

</div>
      

);

}


export default PoolsV6;